
































import { computed, defineComponent, ref } from '@vue/composition-api'
import { goForward, licenses, insurance } from '@/pages/Public/Appraiser/AppraiserRegistration/AppraiserRegistration'
import LicenseRegistrationPartial from '@/pages/Public/Appraiser/AppraiserRegistration/Partials/Subpartials/LicenseRegistrationPartial.vue'
import NonAgentStateRegistration from '@/pages/Public/Appraiser/AgentRegistration/Partials/NonAgentStateRegistration.vue'

/**
 * @name AgentLicenseRegistration
 * Step in vendor registration for vendor to upload license
 *
 * @SetupComputed disableContinue - checks to see if license/insurance is uploaded before continuing.
 */

export default defineComponent({
    components: { NonAgentStateRegistration, LicenseRegistrationPartial },
    props: {
        /**
         * Check for if vendor is an vendor signing up.
         */
        vendor: {
            type: String,
            default: '',
        },
    },
    setup(props) {
        const disableContinue = computed(() => {
            if (props.vendor) return !licenses.value.length
            return !insurance.value || !licenses.value.length
        })

        const hasLicense = ref(false)

        return {
            disableContinue,
            goForward,
            licenses,
            insurance,
            hasLicense,
        }
    },
})
