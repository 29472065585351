<template>
    <v-img src="https://assets.jarodesk.com/SSL-Secure-Connection.png" :max-width="width" />
</template>

<script>
export default {
    name: 'ssl-trust',
    props: {
        width: {
            type: String,
            default: '130',
        },
    },
}
</script>

<style scoped></style>
