



























import { defineComponent, onMounted } from '@vue/composition-api'
import { step } from '@/pages/Public/Appraiser/AppraiserRegistration/AppraiserRegistration'

import AppraiserProfileRegistration from '@/pages/Public/Appraiser/AppraiserRegistration/Partials/AppraiserProfileRegistration.vue'
import CoverageAreaRegistration from '@/pages/Public/Appraiser/AppraiserRegistration/Partials/CoverageAreaRegistration.vue'
import $axios from '@/plugins/axios'
import TaxInformationRegistration from '@/pages/Public/Appraiser/AppraiserRegistration/Partials/TaxInformationRegistration.vue'
import AppraiserAgreementRegistration from '@/pages/Public/Appraiser/AppraiserRegistration/Partials/Subpartials/AppraiserAgreementRegistration.vue'
import AppraiserDwollaRegistration from '@/pages/Public/Appraiser/AppraiserRegistration/AppraiserDwollaRegistration.vue'
import AgentLicenseRegistration from '@/pages/Public/Appraiser/AgentRegistration/Partials/AgentLicenseRegistration.vue'
import VendorBackgroundCheck from '@/pages/Public/Appraiser/AgentRegistration/Partials/VendorBackgroundCheck.vue'

/**
 * @name AmcRegistrationPage
 * Registration page for AMC to sign up as vendors in the system.
 *
 * @onMounted makes a call to check if person has an invite or not through the token in the URL.
 */

export default defineComponent({
    components: {
        VendorBackgroundCheck,
        AgentLicenseRegistration,
        AppraiserProfileRegistration,
        CoverageAreaRegistration,
        TaxInformationRegistration,
        AppraiserAgreementRegistration,
        AppraiserDwollaRegistration,
    },
    setup(_, { root }) {
        onMounted(async () => {
            $axios
                .post('/token-check', {
                    token: root.$route.params.token,
                    type: 'AppraiserInvite',
                    settingType: 'agent'
                })
                .then((response) => {
                    if (!response) window.location.href = '/invite-expired'
                })
                .catch(() => {
                    window.location.href = '/invite-expired'
                })
        })

        return {
            step,
        }
    },
})
