var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-col',[_c('v-row',[_c('v-col',[_c('p',[_vm._v("Please select your primary state where you will complete assignments")]),_c('StateSelect',{model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}})],1)],1),_c('a-card-action',{attrs:{"handleSubmit":handleSubmit,"submitData":{
                file: null,
                state: _vm.state,
                number: null,
                type: 'Inspection',
                expiration: null,
                effective: null,
            },"showCancel":false,"btnText":"Save State","url":'/v1/appraiser/' + _vm.registeredAppraiser.id + '/license'},on:{"success":_vm.saveState}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }