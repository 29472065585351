import { render, staticRenderFns } from "./AgentLicenseRegistration.vue?vue&type=template&id=4bba2fa6&"
import script from "./AgentLicenseRegistration.vue?vue&type=script&lang=ts&"
export * from "./AgentLicenseRegistration.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
installComponents(component, {VBtn,VCol,VRadio,VRadioGroup,VRow,VSpacer,VStepperContent})
