
























import { defineComponent } from '@vue/composition-api'
import { registeredAppraiser, step } from '@/pages/Public/Appraiser/AppraiserRegistration/AppraiserRegistration'
import { Appraiser } from '@/types'
import SubmitBackground from '@/pages/Appraiser/Profile/EditProfile/Partials/Subpartials/SubmitBackground.vue'
import { backgroundLegalInfo, backgroundLegalText } from '@/pages/Appraiser/Appraiser'

export default defineComponent({
    components: { SubmitBackground },
    props: {
        vendor: {
            type: String,
            default: '',
        },
    },
    setup(_, { root }) {
        const moveForward = () => {
            root.$router.push('/appraiser/' + (registeredAppraiser.value as Appraiser).id + '/edit')
        }

        return {
            moveForward,
            backgroundLegalInfo,
            backgroundLegalText,
            registeredAppraiser,
            step,
        }
    },
})
