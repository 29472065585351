import { render, staticRenderFns } from "./AgentRegistrationPage.vue?vue&type=template&id=ffa2b468&"
import script from "./AgentRegistrationPage.vue?vue&type=script&lang=ts&"
export * from "./AgentRegistrationPage.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperItems } from 'vuetify/lib/components/VStepper';
installComponents(component, {VContainer,VRow,VStepper,VStepperItems})
