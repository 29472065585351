




























import { defineComponent, ref } from '@vue/composition-api'
import StateSelect from '@/components/General/AutoCompletes/StateSelect.vue'
import { ValidationObserver } from 'vee-validate'
import { goForward, registeredAppraiser } from '@/pages/Public/Appraiser/AppraiserRegistration/AppraiserRegistration'

export default defineComponent({
    name: 'NonAgentStateRegistration',
    components: { StateSelect, ValidationObserver },
    setup() {
        const state = ref('')
        const saveState = () => {
            goForward()
        }
        return {
            state,
            registeredAppraiser,
            saveState,
        }
    },
})
