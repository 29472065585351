



























































import { ValidationObserver } from 'vee-validate'
import { defineComponent, ref } from '@vue/composition-api'
import $axios from '@/plugins/axios'
import CreditCard from '@/components/General/CreditCard.vue'
import SslTrust from '@/components/General/CreditCard/partials/SslTrust.vue'
import Vue from 'vue/types/umd'
import router from '@/pages/router'
import { user } from '@/plugins/User'

export default defineComponent({
    components: { SslTrust, CreditCard, ValidationObserver },
    methods: {
        submit(): void {
            this.submitting = true
            const credit = this.$refs.credit as Vue & { addCard: () => void }
            credit.addCard()
        },
    },
    props: {
        user_id: {
            type: String,
            default: null,
        },
        renew: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const bg_submit = ref(false)
        const cc_charged = ref(false)
        const bg_link = ref('')
        const bg_error = ref('')
        const submitting = ref(false)
        const loaded = ref(false)
        const success = ref(false)
        const submitBackGround = () => {
            if (!props.user_id) return
            $axios.post('/v1/appraiser/' + props.user_id + '/background').then((response) => {
                if (response.data.message) {
                    bg_error.value = response.data.message
                } else {
                    bg_submit.value = true
                    bg_link.value = response.data.link
                }
            })
        }
        const caughtError = (error: { result?: boolean; message?: string; error?: boolean }) => {
            submitting.value = false
            if (error.message) {
                bg_error.value = error.message
                return
            }
            bg_error.value = "Oops! Something didn't work. Try again!" as string
        }
        const runCard = (response: string) => {
            if (!props.user_id) return
            $axios
                .post('/v1/appraiser/' + props.user_id + '/background', {
                    response: response,
                    token: router.currentRoute.params.token,
                    amount: 39,
                    surcharge: 0,
                    renew: props.renew,
                })
                .then((response) => {
                    submitting.value = false
                    if (response.data.current_screening.status === 'Error') {
                        if (response.data.current_screening.is_charge_error) {
                            bg_error.value = 'Error running Credit Card, please check it and try again'
                        } else {
                            bg_error.value = response.data.current_screening.error
                        }
                    } else {
                        success.value = true
                        bg_submit.value = false
                        cc_charged.value = true
                        bg_link.value = response.data.current_screening.link
                        bg_error.value = ''
                    }
                })
                .catch(() => {
                    bg_error.value = 'Something went wrong, please try again'
                })
        }

        return {
            submitBackGround,
            cc_charged,
            bg_submit,
            bg_link,
            bg_error,
            success,
            submitting,
            loaded,
            runCard,
            caughtError,
            user,
        }
    },
})
